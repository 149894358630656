<template>
  <div class=".gg-container-1">
    <div class="search-container">
      <div
        style="margin-left: auto; display: flex; justify-content: space-around"
      >
      <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('add') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										  background-color: #2362FB; border-color: #2362FB"
            @click="handleDisease(multipleSelectionPres[0])"
            >病症管理
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('add') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										  background-color: #2362FB; border-color: #2362FB"
            @click="departAllot(multipleSelectionPres[0])"
            >科室分配
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('add') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										  background-color: #2362FB; border-color: #2362FB"
            @click="handleTop(multipleSelectionPres[0], 'top')"
            >首页科室
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('add') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										  background-color: #2362FB; border-color: #2362FB"
            @click="handleEdit('add')"
            >新增
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('add') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										  background-color: #2362FB; border-color: #2362FB"
            @click="handleEdit('edit', multipleSelectionPres[0])"
            v-if="multipleSelectionPres.length == 1"
            >编辑
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('delete') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										  background-color: #2362FB; border-color: #2362FB"
            @click="handleEdit('del', multipleSelectionPres[0])"
            v-if="multipleSelectionPres.length == 1"
            >删除
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('update_table') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
											background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('reset_query') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
											background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>
        <!--对应第三方图标的button样式-->
      </div>
    </div>
    <!----------------表格---------------->
    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="725"
      @selection-change="handleSelectionChange"
      style="width: 100%;z-index:0"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              style="font-size: 12px; color: #2379fb"
              @click.prevent="editArticle('view', scope.row)"
            >
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>
        <!-- <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="
            column.field_type === 'image' &&
              column.visible === true
          "
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-avatar :src="scope.row.head_portrait"></el-avatar>
          </template>
        </el-table-column> -->
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.field_type === 'select' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-switch
              class="switch"
              @change="changeExamine(scope.row)"
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="0"
              active-text="启用"
              inactive-text="禁用"
              active-color="#13ce66"
              inactive-color="#C8C8C8"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a v-if="column.column_prop == 'status'">
              {{ scope.row.status == 0 ? "关闭" : "开启" }}
            </a>
            <a v-else-if="column.column_prop == 'esign_pres_status'">
              {{ scope.row.status == 1 ? "静默" : "手动" }}
            </a>
            <a v-else-if="column.column_prop == 'esign_emr_status'">
              {{ scope.row.status == 1 ? "静默" : "手动" }}
            </a>
            <a v-else-if="column.column_prop == 'drug_alias_status'">
              {{ scope.row.status == 0 ? "关闭" : "开启" }}
            </a>
            <a v-else-if="column.column_prop == 'subscribe_status'">
              {{ scope.row.status == 0 ? "关闭" : "开启" }}
            </a>
            <a v-else-if="column.column_prop == 'sms_status'">
              {{ scope.row.status == 0 ? "关闭" : "开启" }}
            </a>
            <a v-else-if="column.column_prop == 'delay_pres_status'">
              {{ scope.row.status == 0 ? "关闭" : "开启" }}
            </a>
            <a v-else-if="column.column_prop == 'auto_tcm_pres_status'">
              {{ scope.row.status == 0 ? "关闭" : "开启" }}
            </a>
            <a v-else-if="column.column_prop == 'tim_status_pres'">
              {{ scope.row.status == 0 ? "不可见" : "可见" }}
            </a>
            <a v-else-if="column.column_prop == 'use_drug_type'">
              {{ scope.row.status == 1 ? "公共药品库" : "自有药品库" }}
            </a>
            <a v-else>{{ scope.row[column.column_prop] }}</a>
          </template>
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-select
                v-if="'source_name' == column.column_prop"
                style="width: 200px"
                @change="
                  handleSearch(column.column_prop, searchParams.source_name)
                "
                v-model="searchParams.source"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="(key, value) in source_name"
                  :key="key"
                  :label="key"
                  :value="value"
                >
                </el-option>
              </el-select>
              <el-input
                v-if="'mobile' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.mobile"
                clearable
                @change="handleSearch(column.column_prop, searchParams.mobile)"
              >
              </el-input>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>

    <!----------------分页---------------->
    <!--<pagination :total="total" :page.sync="listQuery.page" -->
    <!--						:limit.sync="listQuery.limit"-->
    <!--						@pagination="getList"/>-->

    <pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <!--编辑表头-->
    <editTableHead
      :isActiveEditTableHead.sync="
        isActiveEditTableHead
      "
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      @getList="getList"
      :table_type="table_type"
    >
    </editTableHead>
    <el-drawer
      :visible.sync="drawerVisible"
      direction="rtl"
      :append-to-body="true"
      v-loading="listLoading"
      :before-close="handleClosePres"
      @closeDrawer="handleClosePres"
      size="68%"
      :with-header="false"
    >
      <organizationInfo
        @closeDrawer="handleClosePres"
        @getList="getList"
        :info="info"
        :type="type"
        v-if="drawerVisible"
      />
    </el-drawer>
    <el-dialog
      :visible.sync="dialogVisibleDepart"
      width="60%"
      :show-close="false"
    >
      <span slot="title" style="border-bottom:2px solid #2362FB">科室分配</span>
      <el-table
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        row-key="id"
        border
        size="mini"
        v-loading="listLoading"
        :header-cell-style="{ 'text-align': 'center' }"
        :data="departTableData"
        height="725"
        @selection-change="handleSelectionDepart"
        style="width: 100%;z-index:0"
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column type="index" align="center" width="50">
        </el-table-column>
        <el-table-column prop="depart_name" label="科室"></el-table-column>
        <el-table-column prop="icon" label="图标" align="center">
          <template slot-scope="scope">
            <div
              style="display: flex;justify-content: center;align-items: center;"
            >
              <div
                class=""
                style="position: relative;border-radius: 50%;width:60px;height:60px;"
                @mouseover="mouseOver(scope.row)"
                @mouseleave="mouseLeave(scope.row)"
                v-loading="avatar_loading"
              >
                <el-image
                  class="avatars"
                  :class="scope.row.id == id ? 'mouseOverAvatar' : ''"
                  :src="scope.row.icon"
                >
                </el-image>
                <el-upload
                  class="avatar-uploader"
                  :data="qiniuData"
                  :action="actionPath"
                  :show-file-list="false"
                  name="file"
                  :headers="header"
                  :on-success="uploadDocPhotoSuccess"
                >
                  <img
                    v-if="scope.row.id == id"
                    src="../../assets/camera.png"
                    class="mouseOver"
                    @click="imgClick(scope.row)"
                  />
                </el-upload>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="排序" v-if="type_depart == 'top'" align="center">
          <template slot-scope="scope">
            <el-input v-model="scope.row.sort" size="mini" placeholder="请输入排序" style="width: 100px">
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="sort"
          label="是否首页"
          v-if="type_depart == 'top'"
          align="center"
        >
          <template slot-scope="scope">
            <el-switch
              :active-value="1"
              v-model="scope.row.is_index"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :inactive-value="0"
            >
            </el-switch>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleDepart = false" size="mini"
          >取 消</el-button
        >
        <el-button type="primary" @click="_ok" size="mini">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="diseaseVisible"
      width="60%"
      :show-close="false"
    >
      <span slot="title" style="border-bottom:2px solid #2362FB">选择病症管理</span>
      <diseaseDialog
      :agent_id="multipleSelectionPres[0].agent_id"
        @closeDrawer="handleClosePres1"
        v-if="diseaseVisible"
      />
    </el-dialog>
  </div>
</template>

<script>
import editTableHead from "@/components/editTableHead/editTableHead";
import { getAdminFieldIndex } from "@/api/drugs2.0";
import pagination from "@/components/Pagination";
import organizationInfo from "./components/organizationInfo.vue";
import diseaseDialog from "./components/diseaseDialog.vue";
import { getToken } from "@/utils/auth";
import { callMethod, getOrgList, getQNToken } from "@/api/basic";
import config from "@/utils/config";
import {
  getAgentSetIndex,
  getAgentSetDetail,
  delAgentSetDelete,
  getAgentSetAgentDeparts,
  getAgentSetNormalDeparts,
  postAgentSetSetAgentDepart,
  postAgentSetSetHomeAgentDepart
} from "@/api/systemManage";
import {mapState} from "vuex";

export default {
  name: "organizationList",
  components: {
    editTableHead,
    pagination,
    organizationInfo,
    diseaseDialog
  },
  computed:{
    ...mapState({
      table_options: (state) => state.user.table_options,
    })
  },
  async created() {
    this.getToken();
    await this._getAdminFieldIndex();
    await this.getList();
  },
  filters: {
    genderFormat(v) {
      if (v === 0) {
        return "未知";
      } else if (v === 1) {
        return "男";
      } else {
        return "女";
      }
    },
  },
  mounted() {
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      this.operateList = res;
    });
  },
  data() {
    return {
      table_type:'organizationList',
      qiniuUrl: config.qiniuUrl,
      id: null,
      qiniuData: {
        type: "",
      },
      header: {
        Authorization: "Bearer " + getToken(),
      },
      actionPath: "/admin/agent-set/uploadFile",
      avatar_loading: false,
      mouseOverImg: false,
      dialogVisibleDepart: false,
      operateList: [],
      isActiveEditTableHead: false,
      multipleSelectionPres: [],
      info: {
        default_phar_id:'',
        agent_id: "",
        app_id: "",
        secret: "",
        mini_app_name: "",
        use_drug_type: "",
        cert_path: "",
        key_path: "",
        speed_doctor_id: "",
        express_fee: "",
        sms_app_id: "",
        sms_app_key: "",
        time_during: "",
        sms_sign_name: "",
        diag_num: "",
        official_account: "",
        color: "",
        cs_mobile: "",
        notify_url: "",
        esign_pres_status: "",
        esign_emr_status: "",
        drug_alias_status: "",
        subscribe_status: "",
        gray_status: "",
        sms_status: "",
        delay_pres_status: "",
        auto_tcm_pres_status: "",
        tim_status_pres: "",
        status: "",
        seal_url: "",
        qrcode: "",
        poster_img: "",
        cover_type:1,
        upload_type:1,
        phar_pres_sign_status:1,//药师签章状态
        subscribe: [
          {
            sub_key: "",
            sub_name: "",
            value: 0,
          },
        ],
      },
      // multipleSelection1: [],
      activities: [],
      tableHead: [],
      searchTableHead: [
        //控制搜索
        {
          name: "mobile",
          isSearch: false,
        },
        {
          name: "source_name",
          isSearch: false,
        },
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      dialogData: {},
      dialogVisible: false,
      fail_info: [],
      patientManagementDialogsVisible: false,
      isActiveDialogs: false,
      source_name: {
        1: "患者场景",
        2: "慢病场景",
        4: "医生场景",
      },
      listLoading: false,
      searchParams: {
        timeValue: [],
      },
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      total: 0,
      tableData: [],
      drawerVisible: false,
      type: "add",
      departTableData: [],
      departList: [],
      depart_id: null,
      type_depart: "",
      diseaseVisible:false,
    };
  },
  methods: {
    //病症管理
    handleDisease(row){
      if (this.multipleSelectionPres.length == 0) {
        this.$message({
          type: "warning",
          message: "请选择一条数据",
        });
        return false;
      } else if (this.multipleSelectionPres.length > 1) {
        this.$message({
          type: "warning",
          message: "只能选择一条数据",
        });
        return false;
      }
      this.diseaseVisible = true
    },
    imgClick(row) {
      console.log(row, "点击图片");
      this.depart_id = row.id;
    },
    uploadDocPhotoSuccess(response, file, fileList) {
      console.log(response.data.url, file, fileList, this.id, 1111);
      let url = response.data.url;
      if (response.code == 200) {
        this.$message({
          type: "success",
          message: "上传成功",
        });
        this.departTableData.forEach((item) => {
          if (this.depart_id == item.id) {
            console.log(item, "1111");
            item.icon = url;
          }else{
            if(item.children){
              item.children.forEach((item1) => {
                if (this.depart_id == item1.id) {
                  console.log(item1, "2222");
                  item1.icon = url;
                }
              });
            }
          }
        });
        console.log(this.departTableData);
      } else {
        this.$message({
          type: "error",
          message: "上传失败",
        });
      }
    },
    getToken() {
      getQNToken()
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            this.qiniuData.token = data.data.token;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    beforeAvatarUpload(file, type, name) {
      console.log(file, type, name);
      switch (type) {
        case "icon":
          this.id_front_loading = true;
          break;
      }
      this.qiniuData.type = type;
      this.qiniuData.key = name; // 通过设置key为文件名，上传到七牛中会显示对应的图片名
    },
    mouseLeave(row) {
      console.log(row);
      this.id = null;
      // this.mouseOverImg = false;
    },
    mouseOver(row) {
      console.log(row, "jinru");
      this.id = row.id;
      // this.mouseOverImg = true;
    },
    _ok() {
      console.log(this.departList);
      if(this.type_depart == 'top'){
        let depart1 = [];
        this.departList.forEach((item) => {
          depart1.push({
            depart_id: item.id,
            sort: item.sort,
            is_index: item.is_index,
          });
        });
        let data = {
          agent_id: this.multipleSelectionPres[0].agent_id,
          depart: depart1,
        };
        console.log(data);
        postAgentSetSetHomeAgentDepart(data).then((res) => {
          this.$message({
            type: "success",
            message: "分配成功",
          });
          this.dialogVisibleDepart = false;
        });
        }else{
        let depart = [];
      this.departList.forEach((item) => {
        depart.push({
          depart_id: item.id,
          icon: item.icon,
        });
      });
      let data = {
        agent_id: this.multipleSelectionPres[0].agent_id,
        depart: depart,
      };
      console.log(data);
      postAgentSetSetAgentDepart(data).then((res) => {
        this.$message({
          type: "success",
          message: "分配成功",
        });
        this.dialogVisibleDepart = false;
      });
      }
    },
    //勾选科室
    handleSelectionDepart(e) {
      console.log(e);
      this.departList = e;
    },
    async getAgentSetNormalDeparts() {
      let data = {
        agent_id: this.multipleSelectionPres[0].agent_id,
      };
      await getAgentSetNormalDeparts(data).then((res) => {
        this.departTableData = res.data.list;
      });
    },
    async getAgentSetAgentDeparts() {
      let data = {
        agent_id: this.multipleSelectionPres[0].agent_id,
      };
      await getAgentSetAgentDeparts(data).then((res) => {
        this.departTableData = res.data;
      });
    },
    //分配科室
    async departAllot(row) {
      if (this.multipleSelectionPres.length == 0) {
        this.$message({
          type: "warning",
          message: "请选择一条数据",
        });
        return false;
      } else if (this.multipleSelectionPres.length > 1) {
        this.$message({
          type: "warning",
          message: "只能选择一条数据",
        });
        return false;
      }
      this.type_depart = '';
      await this.getAgentSetNormalDeparts();
      this.dialogVisibleDepart = true;
    },
    //设为置顶
    async handleTop(row, type) {
      if (this.multipleSelectionPres.length == 0) {
        this.$message({
          type: "warning",
          message: "请选择一条数据",
        });
        return false;
      } else if (this.multipleSelectionPres.length > 1) {
        this.$message({
          type: "warning",
          message: "只能选择一条数据",
        });
        return false;
      }
      this.type_depart = type;
      await this.getAgentSetAgentDeparts();
      this.dialogVisibleDepart = true;
    },
    //编辑按钮
    async handleEdit(type, row) {
      this.type = type;
      if (type == "add") {
        this.drawerVisible = true;
      } else if (type == "edit") {
        await getAgentSetDetail(row.id).then((res) => {
          this.info = res.data;
        });
        this.drawerVisible = true;
      } else {
        this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            delAgentSetDelete(row.id).then((res) => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList("restPage");
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    handleSelectionChange(val) {
      console.log(val);
      let tmp = val.map((v, k, a) => {
        return v.appraise_id;
      });
      this.multipleSelection = `[${tmp.join(",")}]`;
      console.log(this.multipleSelection, 43435435);
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelectionPres.push(item);
      });
    },
    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleClosePres() {
      this.drawerVisible = false;
      this.getList("restPage");
    },
    handleClosePres1() {
      this.diseaseVisible = false;
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    async _getAdminFieldIndex() {
      console.log(this.table_options.organizationList);
      this.tableHead = this.table_options.organizationList;
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "organizationList",
      //   };
      //   const res = await getAdminFieldIndex(params);
      //   this.tableHead = res.data;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    getList(rest) {
      if (rest === "restPage") {
        this.listQuery.page = 1;
      }
      this.listLoading = true;
      let params = {
        ...this.searchParams,
      };
      params.page = this.listQuery.page;
      params.limit = this.listQuery.limit;
      getAgentSetIndex(params)
        .then((response) => {
          let data = response;
          if (data.code === 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = Number(_data.per_page);
            this.tableData = _data.list;
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
    closeDrawer(e) {
      this.drawerVisible = e;
    },
  },
};
</script>

<style scoped lang="scss">
.gg-container {
  position: relative;
  //   height:100vh
}

.table-username {
  font-size: 14px;
  color: #2632fb;

  &:hover {
    cursor: pointer;
  }
}

/*drawer*/
/deep/ .el-drawer {
  bottom: 0 !important;
}

/deep/ .el-drawer__header {
  padding: 0;
  margin: 0;
}

/deep/ .el-drawer.rtl {
  overflow: visible;
  //   height: 91vh;
  bottom: 0;
  //   margin-top: 9vh;
  position: absolute;
}
.mouseOverAvatar {
  opacity: 0.5;
}
.avatars {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.mouseOver {
  position: absolute;
  width: 68px;
  height: 68px;
  top: -4px;
  left: -4px;
  opacity: 0.6;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 298px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatars {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
}
.elUpload {
  text-align: center;
  position: relative;

  .avatar-uploader {
    border: 1px dotted #666;
  }

  .el-upload {
    width: 100%;

    .avatar-uploader-icon {
      width: 100%;
    }
  }

  .avatar {
    max-width: 100%;
  }

  .del {
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    font-size: 30px;
    color: red;
    cursor: pointer;
  }
}

.addExperience {
  border: 1px solid #f5f5f5;
  color: #a7a7a7;
  text-align: center;
}

.addExperience:hover {
  cursor: pointer;
}

.docPhoto {
  width: 160px;
}

.el-date-editor.el-input {
  width: auto;
}
</style>
