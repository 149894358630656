<template>
  <div>
    <div style="margin-bottom:10px;width:100%;text-align: right;">
      <el-input
        style="width: 200px"
        size="small"
        placeholder="关键字搜索"
        prefix-icon="el-icon-search"
        v-model="searchParams.symptom_name"
        clearable
        @change="getList('rest')"
      >
      </el-input>
    </div>
    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="725"
      @selection-change="handleSelectionChange"
      style="width: 100%;z-index:0"
      :row-key="getRowKeys"
      ref="multipleTable"
    >
      <el-table-column
        type="selection"
        width="55"
        align="center"
        :reserve-selection="true"
      />
      <el-table-column type="index" align="center" width="50"></el-table-column>
      <el-table-column prop="symptom_code" align="center" label="编码">
      </el-table-column>
      <el-table-column prop="symptom_name" align="center" label="病状名称">
      </el-table-column>
      <el-table-column prop="depart_name" align="center" label="所属科室">
      </el-table-column>
    </el-table>
    <pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <div style="width:100%;text-align: right;">
      <el-button
        type="primary"
        size="mini"
        @click="handleCancel"
        style="margin-top: 10px"
        >取 消</el-button
      >
      <el-button
        type="primary"
        size="mini"
        @click="_ok"
        style="margin-top: 10px"
        >确 认</el-button
      >
    </div>
  </div>
</template>

<script>
import { getHospitalSymptomList } from "@/api/basic/index";
import pagination from "@/components/Pagination";
import { postSetAgentSymptom, getSetAgentSymptom } from "@/api/systemManage";
export default {
  props: {
    agent_id: {
      type: Number,
      required: true,
    },
  },
  components: {
    pagination,
  },
  created() {
    this.getList();
    console.log(this.agent_id, 12312321);
  },
  mounted() {},
  data() {
    return {
      listLoading: false,
      searchParams: {
        timeValue: [],
      },
      listQuery: {
        page: 1,
        limit: 100,
        status: 1,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      total: 0,
      tableData: [],
      selectData: [],
      symptom_ids: [],
    };
  },
  methods: {
    _ok() {
      console.log(this.symptom_ids, this.selectData);
      this.symptom_ids = [];
      this.selectData.forEach((item) => {
        this.symptom_ids.push(item.id);
      });
      let data = {
        agent_id: this.agent_id,
        symptom_ids: this.symptom_ids,
      };
      postSetAgentSymptom(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "设置成功",
            type: "success",
          });
          this.$emit("closeDrawer", false);
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    handleCancel() {
      this.$emit("closeDrawer", false);
    },
    getRowKeys(row) {
      return row.id;
    },
    getList(rest) {
      if (rest == "restPage") {
        this.listQuery.page = 1;
      }
      this.listLoading = true;
      let params = {
        ...this.searchParams,
      };
      params.page = this.listQuery.page;
      params.status = this.listQuery.status;
      params.limit = this.listQuery.limit;
      params.agent_id = this.agent_id;
      getSetAgentSymptom(params)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = Number(_data.per_page);
            this.tableData = _data.list;
            this.tableData.forEach((row) => {
              if (row.type == 1) {
                this.$refs.multipleTable.toggleRowSelection(row, true);
              }
            });
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
    handleSelectionChange(val) {
      this.selectData = val;
    },
  },
};
</script>

<style lang="scss" scoped></style>
